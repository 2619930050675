<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="elevators"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      @dblclick:row="onClickedRow"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.state.auth.user.isAdmin"
            dark color="accent" class="mb-2 ml-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un ascenseur' }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.site`]="{ item }">
        {{ item.site }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!--<v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
            >
              <v-icon
                small
                @click="$router.push({ name: 'SiteElevators', params: { id: item.id } })"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Voir</span>
        </v-tooltip> -->
<!--        <v-tooltip top>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-btn-->
<!--              icon small color="purple lighten-2"-->
<!--            >-->
<!--              <v-icon-->
<!--                small-->
<!--                @click="viewDocuments(item)"-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--              >-->
<!--                mdi-file-document-multiple-->
<!--              </v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <span>Voir les documents</span>-->
<!--        </v-tooltip>-->
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small
              :color="item.unloadedAt ? 'success' : 'indigo lighten-2'"
              :disabled="item.audit == null"
            >
              <v-icon
                small
                @click="openUnloading(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-package-down
              </v-icon>
            </v-btn>
          </template>
          <span>Voir le remplissage</span>
        </v-tooltip>
        <v-tooltip top v-if="item.audit != null">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
            >
              <v-icon
                small
                @click="viewAudit(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-comment-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Voir l'audit</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin && item.audit == null"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
            >
              <v-icon
                small
                @click="createAudit(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-comment
              </v-icon>
            </v-btn>
          </template>
          <span>Créer un audit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
            >
              <v-icon
                small
                @click="openItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Voir</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="error"
            >
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditElevator
      ref="editForm"
      :default-site="site"
      @finished="fetchData"
    />
    <RemoveItem
      resource="elevators"
      title="cet ascenseur"
      ref="removeForm"
      @finished="fetchData"
    />
    <UnloadElevator
      ref="unloadForm"
      @finished="fetchData"
    />
  </div>
</template>

<script>
import EditElevator from "./EditElevator.vue"
import UnloadElevator from "./UnloadElevator.vue"
import RemoveItem from "../RemoveItem.vue"

export default {
  components: { EditElevator, RemoveItem, UnloadElevator },
  props: {
    // to filter the list of sites by the site they belong to
    site: {
      type: Object,
      default: null,
    },
    isLinkedToSite: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      tableLoading: false,
      elevators: [],
      options: {},
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: "Numéro d'ascenseurs",
          value: 'code'
        },
        {
          text: 'Constructeur',
          value: 'constructor.name'
        },
        ...(this.site ? [] : [{
          text: 'Site',
          value: 'site'
        }]),
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        if (this.isLinkedToSite && !this.site.id) {
          return
        }
        this.fetchData()
      },
      deep: true,
    },
    site: {
      handler () {
        this.fetchData()
      },
      deep: true,
    }
  },
  methods: {
    fetchData () {
      let additionalParams = {}
      if (this.site) {
        additionalParams.site = this.site.id
      }
      this.tableLoading = true
      this.$store.dispatch(
        'elevators/fetchAll',
        {...this.options, additionalParams }
      ).then(res => {
        this.tableLoading = false
        this.elevators = res.member
        this.totalCount = res.totalItems
      })
    },
    createAudit(elevator) {
      this.$router.push({ name: 'CreateAudit', params: { id: elevator.id } })
    },
    viewAudit(elevator) {
      this.$router.push({ name: 'ElevatorAudit', params: { id: elevator.id } })
      // console.log('Viewing audit', elevator.audit.id)
    },
    viewDocuments(elevator) {
      this.$router.push({ name: 'ElevatorDocuments', params: { id: elevator.id } })
    },
    onClickedRow(_, { item }) {
      //this.$refs.editForm.open(item)
      this.openItem(item)
    },
    openUnloading (item) {
      if (item.unloadedAt) {
        this.$router.push({ name: 'ElevatorUnloading', params: { id: item.id }})
        return
      }
      this.$refs.unloadForm.open(item)
    },
    openItem (item) {
      if (!item.id) {
        throw 'Cannot open elevator, no id found'
      }
      this.$router.push({ name: 'ElevatorAudit', params: { id: item.id } })
    },
  }
}
</script>
