<template>
  <ListElevators
    is-linked-to-site
    :site="site" />
</template>

<script>
import ListElevators from '../../components/Elevators/ListElevators'

export default {
  props: {
    site: {
      type: Object,
      default: null,
    },
  },
  components: { ListElevators }
}
</script>
