var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.elevators,"loading":_vm.tableLoading,"options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.onClickedRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"outlined":"","small":"","icon":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),(_vm.$store.state.auth.user.isAdmin)?_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"dark":"","color":"accent"},on:{"click":function($event){return _vm.$refs.editForm.open()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un ascenseur')+" ")],1):_vm._e()],1)]},proxy:true},{key:"item.site",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.site)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAtObject))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":item.unloadedAt ? 'success' : 'indigo lighten-2',"disabled":item.audit == null}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openUnloading(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-package-down ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir le remplissage")])]):_vm._e(),(item.audit != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.viewAudit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir l'audit")])]):_vm._e(),(_vm.$store.state.auth.user.isAdmin && item.audit == null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.createAudit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment ")])],1)]}}],null,true)},[_c('span',[_vm._v("Créer un audit")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir")])]),(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"info"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.editForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_vm._e(),(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"error"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.removeForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()]}}],null,true)}),_c('EditElevator',{ref:"editForm",attrs:{"default-site":_vm.site},on:{"finished":_vm.fetchData}}),_c('RemoveItem',{ref:"removeForm",attrs:{"resource":"elevators","title":"cet ascenseur"},on:{"finished":_vm.fetchData}}),_c('UnloadElevator',{ref:"unloadForm",on:{"finished":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }