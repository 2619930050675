<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-text-field
          prepend-icon="mdi-label"
          v-model="elevator.code"
          label="Code de l'ascenseur"
        >
        </v-text-field>
        <v-text-field
          prepend-icon=" "
          type="number"
          v-model="elevator.levels"
          label="Nombre de niveaux"
        >
        </v-text-field>
        <v-select
          v-model="elevator.constructor.id"
          :items="constructors"
          prepend-icon="mdi-domain"
          label="Constructeur"
          item-text="name"
          item-value="id"
        >
        </v-select>
        <v-select
          v-model="elevator.payload"
          :items="$store.state.elevators.payload"
          prepend-icon="mdi-weight"
          label="Charge utile (en kg)"
        >
        </v-select>
        <v-text-field
          prepend-icon=" "
          v-model="elevator.site.id"
          :disabled="defaultSite != null"
          label="Chantier associé"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn color="success" text :loading="loading" @click="save">
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import rfdc from "rfdc";
export default {
  computed: {
    formTitle() {
      return this.mode === "create"
        ? "Nouveau ascenseur"
        : "Modification d'un ascenseur";
    },
  },
  props: {
    defaultSite: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    mode: "edit",
    loading: false,
    dialog: false,
    elevator: {
      levels: 1,
      site: {
        id: null,
      },
      constructor: {
        id: null,
      },
    },
    constructors: [],
    resetForm: false,
  }),
  methods: {
    clearForm() {
      this.elevator = {
        code: "",
        levels: 1,
        site: {
          id: this.defaultSite ? this.defaultSite.id : null,
        },
        constructor: {
          id: null,
        },
      };
    },
    open(item = null) {
      // fetch constructors
      this.$store.dispatch("constructors/fetchAll").then((res) => {
        this.constructors = res.member;
        this.constructors.unshift({
          id: null,
          name: "Choisir un constructeur",
        });
      });
      let oldMode = this.mode;
      this.mode = item ? "edit" : "create";
      if (oldMode === "edit" && this.mode === "create") {
        this.clearForm();
      }
      if (this.mode === "edit") {
        this.elevator = rfdc()(item);
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.loading = true;
      let elevator = rfdc()(this.elevator);
      delete elevator.fillingParts;
      delete elevator.audit;
      delete elevator.createdAtObject;
      if (elevator.constructor.id === null) {
        elevator.constructor = null;
      }
      if (elevator.constructor && elevator.constructor.id) {
        elevator.constructor = "/api/constructors/" + elevator.constructor.id;
      }
      if (elevator.site.id === null) {
        elevator.site = null;
      }
      if (elevator.site && elevator.site.id) {
        elevator.site = "/api/sites/" + elevator.site.id;
      }
      if (elevator.levels) {
        elevator.levels = parseInt(elevator.levels);
      }
      if (this.mode === "create") {
        this.$store
          .dispatch("elevators/create", elevator)
          .then(() => {
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "success",
              text: "L'ascenseur a bien été créée",
            });
            this.close();
            this.$emit("finished");
            this.clearForm();
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "error",
              text: "Une erreur est survenue lors de la création de l'ascenseur",
            });
          });
      } else {
        this.$store
          .dispatch("elevators/update", elevator)
          .then(() => {
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "success",
              text: "L'ascenseur a bien été modifié",
            });
            this.close();
            this.$emit("finished");
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
            this.$store.commit("alert/add", {
              color: "error",
              text: "Une erreur est survenue lors de la modification de l'ascenseur",
            });
          });
      }
    },
  },
};
</script>
