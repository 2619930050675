<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card v-if="elevator.id">
      <v-card-title>
        Remplissage de l'ascenseur {{ elevator.code }}
      </v-card-title>
      <v-card-text>
        <v-progress-linear
          v-if="loading"
          indeterminate
        />
        <div v-else>
          <v-row>
            <v-col cols="12" md="6">
              <p class="mt-0 mb-1">Bilan des déchets théoriques prit par Socabat à partir de l'audit n°{{
                  audit.id
                }}</p>
              <!-- Ici on va lister toute les catégories de l'audit et leur masse -->
              <v-list>
                <v-divider/>
                <template v-for="type in audit.typeSummary">
                  <v-list-item :key="type.id + '_i'">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ type.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="d-flex">
                        {{ type.totalMass }} kg
                        <v-icon
                          v-if="missingTypes.find(t => type.id === t.id) || uselessTypes.find(t => type.id === t.id)"
                          class="ml-2"
                          color="warning"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </div>

                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="type.id + '_d'"/>
                </template>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <p class="mt-0">Veuillez cochez les bennes dans lequel ont été déchargés les matériaux de
                  l'ascenseur</p>
                <v-form>
                  <template v-for="container in containers">
                    <v-checkbox
                      :key="'checkbox-' + container.id"
                      :label="container.code"
                      :hint="
                        container.defaultWasteType.name +
                        (container.errorDetails ? ' - ' + container.errorDetails : '') +
                        (container.relationDetails ? ' - ' + container.relationDetails : '')
                      "
                      persistent-hint
                      :disabled="container.disabled"
                      v-model="containerRelations[container.id]"
                    >
                      <template v-slot:append>
                        <v-icon
                          v-if="container.error.type === 'filled'"
                          color="error"
                        >
                          mdi-tray-full
                        </v-icon>
                        <v-icon
                          v-if="container.error.type === 'availability'"
                          color="error"
                        >
                          mdi-lock-outline
                        </v-icon>
                        <v-icon
                          v-if="container.error.type === 'incoherent-cycles'"
                          color="error"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                    </v-checkbox>
                  </template>
                </v-form>
                <!-- <v-expansion-panels class="mb-6">
                  <v-expansion-panel
                    v-for="container in containers"
                    :key="'container-' + container.id"
                  >
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      {{ container.code }} - {{ container.defaultWasteType.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="!container.lastCycles || container.lastCycles.length === 0">
                        Pas encore de cycles
                      </div>
                      <div v-else>
                        <v-checkbox
                          v-for="cycle in container.lastCycles"
                          :key="cycle.id"
                          :label="'Cycle n°' + (cycle.number).toString()"
                          hide-details
                          v-model="relations[cycle.id]"
                        >
                          <template v-slot:append>
                            <div
                              v-if="cycle.fillPercentage != null"
                              style="white-space: nowrap; color: white;"
                              class="mr-2"
                            >
                              {{ cycle.fillPercentage }}%
                            </div>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-alert-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="cycle.inTransit"
                              color="info">
                              mdi-truck-fast-outline
                            </v-icon>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels> -->
              </div>
            </v-col>
          </v-row>
          <div class="mt-8 last-alert-no-margin">
            <v-alert
              v-if="missingContainers"
              type="warning"
            >
              Il manque des bennes pour les types : {{ missingTypes.map(t => '"' + t.name + '"').join(', ') }}
            </v-alert>
            <v-alert
              v-if="uselessContainers"
              type="warning"
            >
              Des bennes sont en trop pour les types : {{ uselessTypes.map(t => '"' + t.name + '"').join(', ') }}
            </v-alert>
            <v-alert
              v-if="!coherentMass"
              type="warning"
            >
              Certains cycles sont surchargés
              Affecte les types : {{ invalidMassTypes.map(t => '"' + t.name + '"').join(', ') }}
            </v-alert>
            <v-alert
              v-if="!isNewUnloading && !readOnly"
              type="warning"
            >
              Ce remplissage a déjà été effectué
            </v-alert>
            <v-alert
              v-if="!isNewUnloading && readOnly"
              type="error"
            >
              Ce remplissage a déjà été effectué, de plus certain cycles ont été rendu indisponibles, vous ne pouvez
              plus le modifier
            </v-alert>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="grey" text @click="close">Fermer</v-btn>
        <v-btn
          color="success" text
          :disabled="readOnly"
          :loading="saveLoading"
          @click="onSubmit"
        >
          Décharger
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { randomString } from '@/utils/random'
import { cleanObject } from "@/utils/cleanObject"

export default {
  data: () => ({
    dialog: false,
    containers: [],
    relations: [],
    containerRelations: {},
    cycleRelations: {},
    audit: {},
    typeSummary: [],
    elevator: {},
    fillingParts: [],
    loading: false,
    saveLoading: false,

    isNewUnloading: false,

    coherentMass: true,
    invalidMassTypes: [],

    missingContainers: false,
    missingTypes: [],

    uselessContainers: false,
    uselessTypes: [],

    readOnly: false,
  }),
  watch: {
    containerRelations: {
      handler() {
        this.generateCycleRelations()
        this.verify()
      },
      deep: true
    }
  },
  methods: {
    reset() {
      this.containerRelations = {}
      this.cycleRelations = {}
      this.missingContainers = false
      this.uselessContainers = false
      this.coherentMass = true
      this.readOnly = false
    },
    open(elevator) {
      this.reset()
      this.elevator = elevator
      if (!this.elevator.audit || !this.elevator.audit.id) {
        this.$store.commit('alert/add', {
          text: "Pour décharger, il faut que cet ascenseur ait un audit",
          color: 'warning'
        })
        return
      }
      this.dialog = true
      this.prepare()
    },
    prepare() {
      this.loading = true
      this.$apitator.get(
        '/elevators/' + this.elevator.id + '/unload/prepare',
        { withAuth: true }
      ).then(res => {
        const data = res.data.data
        this.loading = false
        this.elevator = { id: data.elevator.id }
        this.audit = data.elevator.audit
        console.debug(data.elevator.audit['summary'])

        this.audit.typeSummary = data.elevator.audit['summary'].find(s => s.status === 'TAKEN_BY_SOCOBAT').summary
        this.isNewUnloading = data.elevator.unloadedAt === null

        if (!this.isNewUnloading) {
          this.fillingParts = data.elevator.fillingParts
          // we will check if there is a non-available cycle within the filling parts
          data.elevator.fillingParts.forEach(part => {
            if (!part.cycle.isAvailable) {
              // make this unloading readonly
              this.readOnly = true
            }
          })
        }

        this.containers = this.prepareContainers(data.containers)

        let toPutIds = {}
        if (!this.readOnly) {
          // auto select the last cycle of the first container of each types
          this.audit.typeSummary.forEach(type => {
            let containers = this.containers
              .filter(c => c.defaultWasteType.id === type.id && !c.disabled)
            if (containers.length > 0) {
              let container = containers[0]
              toPutIds[container.id] = true
              console.debug(`> Auto-fill: choosed container ${container.code} for type "${type.name}"`)
            }
          })
        }

        this.containerRelations = toPutIds
        this.verify()
      }).catch(err => {
        console.error(err)
        this.loading = false
        this.$store.commit('alert/add', {
          text: "Une erreur est survenue lors de la préparation du remplissage",
          color: 'error'
        })
      })
    },

    prepareContainers(containers) {
      // container is disabled if there is no cycles available
      // or if we cannot form a relation with it
      // (what we want to put in is too large)

      // if the unloading is already done, we should make sure that the state of each container is the same as the old state (without the unloading)
      console.log('prepare containers', this.fillingParts)
      containers = containers.map(container => {
        let maximumMass = container.defaultWasteType.maximumContainerMass
        return {
          ...container,
          lastCycles: container.lastCycles.map(cycle => {
            let part = this.fillingParts.find(p => p.cycle.id === cycle.id)
            if (part) {
              console.log('replaced one part')
              let oldMass = cycle.mass - part.mass
              return {
                ...cycle,
                mass: oldMass,
                fillPercentage: Math.round(100*(oldMass/maximumMass))
              }
            }
            return cycle
          })
        }
      })

      return containers.map(container => {
        container.disabled = false
        container.error = { type: null }
        if (container.lastCycles.filter(c => c.isAvailable).length === 0) {
          container.disabled = true
          container.error = { type: 'availability' }
          container.errorDetails = 'Aucun cycle disponible'
          return container
        }
        if (
          container.lastCycles.filter(c => c.isAvailable && c.fillPercentage < 100).length === 0
        ) {
          container.disabled = true
          container.error = { type: 'filled' }
          container.errorDetails = 'Tous les cycles sont pleins'
          return container
        }
        if (
          this.getRelationsForContainer(container).length === 0
        ) {
          // on n'est pas dans un scénario approuvé ++
          container.disabled = true
          container.errorDetails = 'Les cycles ne sont pas bien agencés'
          container.error = { type: 'incoherent-cycles' }
        }

        return container
      })
    },

    getRelationsForContainer(container) {
      let cycles = container.lastCycles
        .filter(c => c.isAvailable)
        .filter(c => c.fillPercentage < 100)

      // we sort the cycles by mass in ascending order
      cycles.sort((a, b) => a.mass - b.mass)
      if (cycles.length > 0) {
        // on regarde si ce qu'on veut mettre rentre dans ce cycle
        // on fait ici l'hypothèse que pour chaque type de déchet il y a un seul et un seul container associé, et au maximum deux cycles
        let type = this.audit.typeSummary.find(type => type.id === container.defaultWasteType.id)
        if (type) {
          let maximumMass = container.defaultWasteType.maximumContainerMass
          // first case: the first cycle allow us to put our trash and it's the only one
          if (
            cycles.length === 1 &&
            cycles[0].mass + type.totalMass <= maximumMass
          ) {
            return [{
              cycle: cycles[0],
              relationType: 'NORMAL',
              mass: type.totalMass,
            }]
          }
          // the second case, we have two cycles, the first is empty and the second one can take our shit
          if (
            cycles.length === 2 && cycles[0].mass === 0 &&
            cycles[1].mass + type.totalMass <= maximumMass
          ) {
            return [{
              cycle: cycles[1],
              relationType: 'NORMAL',
              mass: type.totalMass,
            }]
          }
          // the third case: we have two cycles and the first one is empty and the second one will overflow
          // AND the first one will not overflow
          if (
            cycles.length === 2 && cycles[0].mass === 0 &&
            cycles[1].mass + type.totalMass > maximumMass &&
            (type.totalMass - (maximumMass - cycles[1].mass)) <= maximumMass
          ) {
            let transitionId = randomString()
            let normalId = randomString()
            return [
              {
                id: transitionId,
                cycle: cycles[1],
                relationType: 'TRANSITION',
                mass: maximumMass - cycles[1].mass,
                nextFillingPartId: normalId,
              },
              {
                id: normalId,
                cycle: cycles[0],
                relationType: 'NORMAL',
                mass: type.totalMass - (maximumMass - cycles[1].mass),
                // la masse que je veut mettre - celle que j'ai déjà mise
                previousFillingPartId: transitionId,
              },
            ]
          }
          // if no case is matched, we failed
        }
      }

      return []
    },

    generateCycleRelations() {
      console.log('Generate cycles relations')
      let cyclesOut = {}
      let selectedCycles = []

      Object.keys(this.containerRelations)
        .filter(id => this.containerRelations[id])
        .forEach(id => {
          let container = this.containers.find(c => c.id === id)
          let relations = this.getRelationsForContainer(container)
          relations.forEach(r => {
            cyclesOut[r.cycle.id] = true
            selectedCycles.push({
              ...r,
              container: { id: container.id },
            })
          })
        })
      console.debug('selectedCycles', selectedCycles)

      this.containers = this.containers.map(container => {
        let relationDetails = ''

        let all = selectedCycles.filter(s => s.container.id === container.id)
        all.forEach((relation, i) => {
          if (i > 0) {
            relationDetails += ', '
          }
          relationDetails += 'n°' + relation.cycle.number.toString()
          if (relation.relationType === 'NORMAL') {
            relationDetails += ' (normal +' + relation.mass.toString() + ' kg)'
          } else if (relation.relationType === 'TRANSITION') {
            relationDetails += ' (transition +' + relation.mass.toString() + ' kg)'
          }
        })

        return {
          ...container,
          relationDetails
        }
      })
      this.cycleRelations = cyclesOut
      this.relations = selectedCycles
    },

    verify() {
      console.debug('verify triggered')
      if (!this.audit.typeSummary) {
        return
      }
      // PART 1.
      // verify that every type in the audit has at least one container with the same type
      this.missingTypes = []
      this.missingContainers = false
      this.uselessTypes = []
      this.uselessContainers = false
      // we will get every cycles (relations) and then get the type of each cycle

      let typeIds = []
      let allCycles = []
      this.containers.forEach(c => {
        c.lastCycles.forEach(cycle => {
          allCycles.push({
            ...cycle,
            // may change when we consider that in a same container, cycles can have different cycles
            wasteTypeId: c.defaultWasteType.id,
            maximumContainerMass: c.defaultWasteType.maximumContainerMass
          })
        })
      })
      Object.keys(this.cycleRelations)
        .filter(id => this.cycleRelations[id])
        .forEach(id => {
          let typeId = allCycles.filter(c => c.id === id)[0].wasteTypeId
          if (typeIds.indexOf(typeId) === -1) {
            typeIds.push(typeId)
          }
        })

      // get the wanted types from the audit summary
      let wantedTypesId = this.audit.typeSummary.map(t => t.id)

      // check if all wanted types are in the cycles
      wantedTypesId.forEach(typeId => {
        if (typeIds.indexOf(typeId) === -1) {
          this.missingContainers = true
          this.missingTypes.push(this.audit.typeSummary.find(t => t.id === typeId))
        }
      })
      typeIds.forEach(typeId => {
        if (wantedTypesId.indexOf(typeId) === -1) {
          this.uselessContainers = true
          this.uselessTypes.push(this.containers.find(c => c.defaultWasteType.id === typeId).defaultWasteType)
        }
      })

      // PART 2.
      // verify that the weight is coherent
      // verify that the weight of each cycle after the unloading is whithin the maximum allowed
      this.coherentMass = true
      this.invalidMassTypes = []
      this.audit.typeSummary.forEach(type => {
        let massToUnload = type.totalMass
        // get the cycles of this type
        let remainingMass = 0
        let relations = Object.keys(this.cycleRelations)
          .filter(cycleId => this.cycleRelations[cycleId])
          .map(cycleId => allCycles.find(c => c.id === cycleId))
          .filter(c => c.wasteTypeId === type.id)
        if (relations.length === 0) {
          return
        }
        relations.forEach(cycle => {
          remainingMass += cycle.maximumContainerMass - cycle.mass
        })
        // si la masse à déposser pour cette catégorie est SUP à la somme des masses restante de chaque cycle coché
        // alors on considère qu'il y a une erreur pour cette catéogorie
        if (massToUnload >= remainingMass) {
          this.coherentMass = false
          this.invalidMassTypes.push(type)
        }
      })

    },
    close() {
      this.dialog = false
    },
    onSubmit() {
      if (this.readOnly) {
        return
      }
      this.saveLoading = true
      let relations = this.relations.map((r) => {
        return {
          id: r.id ? r.id : randomString(),
          cycleId: r.cycle.id,
          relationType: 'TYPE_' + r.relationType,
          mass: r.mass,
          nextFillingPartId: r.nextFillingPartId ? r.nextFillingPartId : null,
          previousFillingPartId: r.previousFillingPartId ? r.previousFillingPartId : null,
        }
      })
      console.debug('Unloading payload', cleanObject(relations))

      this.$apitator.post(
        '/elevators/' + this.elevator.id + '/unload/execute',
        { relations },
        { withAuth: true }
      ).then(res => {
        this.saveLoading = false
        if (this.isNewUnloading) {
          this.$store.commit('alert/add', {
            color: 'success',
            text: 'Remplissage effectué !'
          })
        } else {
          this.$store.commit('alert/add', {
            color: 'success',
            text: 'Remplissage mis à jour !'
          })
        }
        this.$emit('finished')
        this.close()
      }).catch(() => {
        this.saveLoading = false
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Impossible d'effectuer le remplissage"
        })
      })
    },
  }
}
</script>

<style>
.last-alert-no-margin .v-alert:last-child {
  margin-bottom: 0;
}
</style>
